import React, { useState } from 'react';
import styles from "./form.module.scss";
import { Button, Typography, Alert, TextField, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useHandleLogin } from './useHandleLogin.hook';

const visibilityIcon = '/images/visibility.svg';
const visibilityOffIcon = '/images/visibilityoff.svg'

export default function LoginFrom() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  // login hook handler
  const { loading, error, data, handleLogin } = useHandleLogin()

  // formik config
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email("Please enter a valid email").required("Please enter an email for sign in"),
    password: Yup.string().required("Your password is required")
  })
  const initialValues = { email: "", password: "" }
  const onSubmit = (values) => {
    values.email = values.email.trim()
    handleLogin({ ...values, googleSignUp: false })
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePush = () => {
    navigate('/signup');
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ touched, errors, values, handleChange }) => {
        return (
          <Form
            className={styles.form}
          >
            {loading && <Alert severity="info">Loading...</Alert>}
            {data && <Alert severity="success">Login successful</Alert>}
            {error && <Alert severity='error'>{error}</Alert>}
            <div className='inputBox'>
              <Typography variant='body2'>Email adress</Typography>
              <TextField
                placeholder="username@gmail.com"
                name="email"
                value={values.email}
                onChange={handleChange}
                about="loginput"
              />
              {errors.email && touched.email && <Typography variant='body2' color="red">{errors.email}</Typography>}
            </div>
            <div className='inputBox'>
              <Typography variant='body2'>Password</Typography>
              <TextField
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={values.password}
                onChange={handleChange}
                about="loginput"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <img src={visibilityIcon} alt="eye icon" /> : <img src={visibilityOffIcon} alt="eye icon" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && touched.password && <Typography variant='body2' color="red">{errors.password}</Typography>}
            </div>
            <div className='inputBox'>
              <Button variant='contained' color='primary' type='submit'>Sign In</Button>
            </div>
            <div className='linkTo'>
              <Typography variant='body2'>Don't have an account? </Typography>
              <Typography variant='body2' className='link' onClick={handlePush}> Sign Up </Typography>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
}
