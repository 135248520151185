import React from 'react'

export default function PrintifyTrackUrl({ trackUrl }) {
    return (
        <div >
            <div className="od-title">Real Time Tracking</div>
            <iframe src={trackUrl} frameborder="0" style={{
                width: "100%",
                height: "500px",
                border: "none",
                backgroundColor:"white"
            }}
            title="tracking of product"
            ></iframe>
        </div>
    )
}
