import React, { useContext } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Box,
} from '@mui/material';
import { CHECKOUT_CONTEXT } from './Checkout';

const steps = ['Delivery Information', 'Shipping Selection'];

function BlackButtonStepper() {
    const { stage: activeStep } = useContext(CHECKOUT_CONTEXT)

    return (
        <Box sx={{
            width: '100%', marginBottom: "44px"
        }}>
            <Stepper activeStep={activeStep} sx={{ marginBottom: 4 }
            } >
                {
                    steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                }
            </ Stepper>
        </Box>
    );
}

export default BlackButtonStepper;
