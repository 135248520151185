import { useContext } from "react";
import { PRODUCT_DETAILS_CONTEXT } from "./productDetails";

export function SizeGuide() {
    const { data } = useContext(PRODUCT_DETAILS_CONTEXT)
    const theInfo=`
${data.description}
${data.size_guide ? `<div style="paddingTop:20px"><h3>Size Guide</h3>${data.size_guide}</div>` : ""}
    `
    return <div>
        <div className="item-title">Description</div>
        <div dangerouslySetInnerHTML={{ __html: theInfo }} className="description"></div>
    </div>;
}