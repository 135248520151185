import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GET_USER_INFO } from "../graphql/userInfo.query";
import CircularProgressComponent from "../components/circularProgress/CircularProgress.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../utils/appRoutes";
import { useDispatch } from "react-redux";
import { setCurrentUserType } from "../redux/mainPageSlice";

export default function GaurdUserTypeRoutes(props) {
  const { children, userType } = props
  const { loading, data, error } = useQuery(GET_USER_INFO, { fetchPolicy: "no-cache" });
  const [next, setNext] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    const handleDifferentUsers = () => {
      console.log("the  user info", data.users[0].user_type)
      if (data.users[0].user_type !== userType) {
        navigate(appRoutes.login);
      } else {
        dispatch(setCurrentUserType(data.users[0].user_type));
        setNext(true);
      }
    }

    if (data) {
      if (props.isInternal) {
        if (!data.users[0].is_internal) {
          navigate(appRoutes.login);
        } else {
          handleDifferentUsers()
        }
      }
      else {
        handleDifferentUsers()
      }
    }
    if (error) {
      navigate(appRoutes.login);
    }
  }, [data, error]);

  return (
    <>
      {loading && <CircularProgressComponent />}
      {error && <div>There was an error</div>}
      {next && <>{children}</>}
    </>
  );
}
