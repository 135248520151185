import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import { handleLookAtMesh } from "../customization/handleLookAtMesh";
import { setTheInfoCanvas } from "../customization/setTheInfoCanvas";
import { CanvasTexture, sRGBEncoding, Color } from "three";

export const GLB_NAME = "source_to_modify";

export const handleGlb = (
  glbPath,
  scene,
  LoadingManager,
  canvas,
  renderer,
  camera,
  controls,
  patternInfo
) => {
  const glbLoader = new GLTFLoader(LoadingManager);
  const dracoLoader = new DRACOLoader(LoadingManager);

  dracoLoader.setDecoderPath("https://threejs.org/examples/jsm/libs/draco/");
  dracoLoader.preload();
  glbLoader.setDRACOLoader(dracoLoader);
  const meshOptLoader = glbLoader;
  meshOptLoader.setMeshoptDecoder(MeshoptDecoder);
  const ktx2Loader = new KTX2Loader();
  ktx2Loader.setTranscoderPath("https://threejs.org/examples/jsm/libs/basis/");
  ktx2Loader.detectSupport(renderer);
  meshOptLoader.setKTX2Loader(ktx2Loader);
  glbLoader.load(glbPath, (glb) => {
    const model = glb.scene;
    model.name = GLB_NAME;
    scene.add(model);
    scene.traverse((item) => {
      if (item.isMesh && item.material) {
        const { the_pattern_info } = setTheInfoCanvas(item, patternInfo);
        if (the_pattern_info) {
          const canvasEl = document.getElementById(the_pattern_info.id);
          const texture = new CanvasTexture(canvasEl);
          texture.encoding = sRGBEncoding;
          texture.needsUpdate = true;
          texture.flipY = false;
          item.material.map = texture;
          item.material.color = new Color(0xffffff);
        }
      }
    });
    handleLookAtMesh(camera, model, controls);
  });
};
